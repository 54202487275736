import { default as amnesiaE7qBR6eobZMeta } from "/var/www/admin-next/pages/amnesia.vue?macro=true";
import { default as analyticspw3Pa5v8qGMeta } from "/var/www/admin-next/pages/analytics.vue?macro=true";
import { default as _91id_93sWiB0RojTfMeta } from "/var/www/admin-next/pages/companies/[id].vue?macro=true";
import { default as indexVRQczgE2XJMeta } from "/var/www/admin-next/pages/companies/index.vue?macro=true";
import { default as aml_45resourcesOGNZLD8fp8Meta } from "/var/www/admin-next/pages/compliance/aml-resources.vue?macro=true";
import { default as dnc_45listfXGi7Irg54Meta } from "/var/www/admin-next/pages/compliance/dnc-list.vue?macro=true";
import { default as indexsJF9p6pXvPMeta } from "/var/www/admin-next/pages/compliance/index.vue?macro=true";
import { default as ppm_45logsMJU0CdOY3zMeta } from "/var/www/admin-next/pages/compliance/ppm-logs.vue?macro=true";
import { default as tasksOt9LJukJ4EMeta } from "/var/www/admin-next/pages/compliance/tasks.vue?macro=true";
import { default as compliance3BpVEwIV3nMeta } from "/var/www/admin-next/pages/compliance.vue?macro=true";
import { default as indexkIoRXKzzOXMeta } from "/var/www/admin-next/pages/deals/[id]/index.vue?macro=true";
import { default as pro_45rata_45allocationsncfWqzzhkoMeta } from "/var/www/admin-next/pages/deals/[id]/pro-rata-allocations.vue?macro=true";
import { default as indexYIyQJa5R1kMeta } from "/var/www/admin-next/pages/deals/index.vue?macro=true";
import { default as _91id_93u6HWwcKKkTMeta } from "/var/www/admin-next/pages/documents/[id].vue?macro=true";
import { default as indexWQ28zNLZNaMeta } from "/var/www/admin-next/pages/documents/index.vue?macro=true";
import { default as _91id_93Tb1EAKk8hUMeta } from "/var/www/admin-next/pages/events/[id].vue?macro=true";
import { default as index4GG0RAIc0fMeta } from "/var/www/admin-next/pages/index.vue?macro=true";
import { default as _91id_93AzeYZIaeBwMeta } from "/var/www/admin-next/pages/institutions/[id].vue?macro=true";
import { default as indexYevg1XzVlTMeta } from "/var/www/admin-next/pages/institutions/index.vue?macro=true";
import { default as _91id_93Gj75jKhV7xMeta } from "/var/www/admin-next/pages/investors/[id].vue?macro=true";
import { default as loginFXjyPfbvrSMeta } from "/var/www/admin-next/pages/login.vue?macro=true";
import { default as companiesLfIf1wlGvOMeta } from "/var/www/admin-next/pages/merge/companies.vue?macro=true";
import { default as indexd55yJXafCTMeta } from "/var/www/admin-next/pages/merge/index.vue?macro=true";
import { default as institutionsLgs0X1CRjMMeta } from "/var/www/admin-next/pages/merge/institutions.vue?macro=true";
import { default as tagsPimDyT0jBrMeta } from "/var/www/admin-next/pages/merge/tags.vue?macro=true";
import { default as usersgfnu506JKuMeta } from "/var/www/admin-next/pages/merge/users.vue?macro=true";
import { default as mergev7gk2txNtZMeta } from "/var/www/admin-next/pages/merge.vue?macro=true";
import { default as outreach6hSNeTQ55qMeta } from "/var/www/admin-next/pages/outreach.vue?macro=true";
import { default as profilemXelTg8cHsMeta } from "/var/www/admin-next/pages/profile.vue?macro=true";
import { default as _91id_93N5JAmTjXDUMeta } from "/var/www/admin-next/pages/projects/[id].vue?macro=true";
import { default as indexcaHGxYcU7jMeta } from "/var/www/admin-next/pages/projects/index.vue?macro=true";
import { default as importelr19r0cTEMeta } from "/var/www/admin-next/pages/prospects/import.vue?macro=true";
import { default as indexoF8PxSIQXfMeta } from "/var/www/admin-next/pages/prospects/index.vue?macro=true";
import { default as _91id_93V5ohf6ePPHMeta } from "/var/www/admin-next/pages/prospects/lists/[id].vue?macro=true";
import { default as outreach_45analyticsYs07H2Qs8UMeta } from "/var/www/admin-next/pages/prospects/outreach-analytics.vue?macro=true";
import { default as referralsGAQDtX6OxYMeta } from "/var/www/admin-next/pages/prospects/referrals.vue?macro=true";
import { default as prospectskFQp4wPKedMeta } from "/var/www/admin-next/pages/prospects.vue?macro=true";
import { default as _91id_93xLEWzvq6SaMeta } from "/var/www/admin-next/pages/research/[id].vue?macro=true";
import { default as indexKPgsDvC9F1Meta } from "/var/www/admin-next/pages/research/index.vue?macro=true";
import { default as account_45typesassBQ3Uv4OMeta } from "/var/www/admin-next/pages/settings/account-types.vue?macro=true";
import { default as company_45types6WT1SBm4s5Meta } from "/var/www/admin-next/pages/settings/company-types.vue?macro=true";
import { default as deal_45stageslpLtUk1aECMeta } from "/var/www/admin-next/pages/settings/deal-stages.vue?macro=true";
import { default as deal_45typeswtVUuhekeAMeta } from "/var/www/admin-next/pages/settings/deal-types.vue?macro=true";
import { default as document_45categoriesJW9xC13W6wMeta } from "/var/www/admin-next/pages/settings/document-categories.vue?macro=true";
import { default as employment_45rolesGOcbG5aUJpMeta } from "/var/www/admin-next/pages/settings/employment-roles.vue?macro=true";
import { default as event_45typesRxT2n57QQtMeta } from "/var/www/admin-next/pages/settings/event-types.vue?macro=true";
import { default as expense_45typesxAEwXTarlrMeta } from "/var/www/admin-next/pages/settings/expense-types.vue?macro=true";
import { default as index2Rsszeb6xvMeta } from "/var/www/admin-next/pages/settings/index.vue?macro=true";
import { default as industrieswdKzbrXXjdMeta } from "/var/www/admin-next/pages/settings/industries.vue?macro=true";
import { default as labelsF8FYnW6eVhMeta } from "/var/www/admin-next/pages/settings/labels.vue?macro=true";
import { default as licensesvJG1wtCtQaMeta } from "/var/www/admin-next/pages/settings/licenses.vue?macro=true";
import { default as research_45categoriesrlyhepruGvMeta } from "/var/www/admin-next/pages/settings/research-categories.vue?macro=true";
import { default as task_45prioritiesPneHpNZ19iMeta } from "/var/www/admin-next/pages/settings/task-priorities.vue?macro=true";
import { default as task_45types7DeTLpTvKRMeta } from "/var/www/admin-next/pages/settings/task-types.vue?macro=true";
import { default as user_45typeswjhUDXfDTSMeta } from "/var/www/admin-next/pages/settings/user-types.vue?macro=true";
import { default as settingsliVKSfWa3BMeta } from "/var/www/admin-next/pages/settings.vue?macro=true";
import { default as _91id_93Xgo5YR8D8SMeta } from "/var/www/admin-next/pages/tags/[id].vue?macro=true";
import { default as indexWp6gxNXGkpMeta } from "/var/www/admin-next/pages/tags/index.vue?macro=true";
import { default as _91id_93BX0B7iS5WwMeta } from "/var/www/admin-next/pages/tasks/[id].vue?macro=true";
import { default as index91QWFViUzuMeta } from "/var/www/admin-next/pages/tasks/index.vue?macro=true";
import { default as _91id_93QVl4LCf5NmMeta } from "/var/www/admin-next/pages/users/[id].vue?macro=true";
import { default as indexOA5qIWUHIwMeta } from "/var/www/admin-next/pages/users/index.vue?macro=true";
export default [
  {
    name: "amnesia",
    path: "/amnesia",
    component: () => import("/var/www/admin-next/pages/amnesia.vue")
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/var/www/admin-next/pages/analytics.vue")
  },
  {
    name: "companies-id",
    path: "/companies/:id()",
    component: () => import("/var/www/admin-next/pages/companies/[id].vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/var/www/admin-next/pages/companies/index.vue")
  },
  {
    name: compliance3BpVEwIV3nMeta?.name,
    path: "/compliance",
    component: () => import("/var/www/admin-next/pages/compliance.vue"),
    children: [
  {
    name: "compliance-aml-resources",
    path: "aml-resources",
    component: () => import("/var/www/admin-next/pages/compliance/aml-resources.vue")
  },
  {
    name: "compliance-dnc-list",
    path: "dnc-list",
    component: () => import("/var/www/admin-next/pages/compliance/dnc-list.vue")
  },
  {
    name: "compliance",
    path: "",
    component: () => import("/var/www/admin-next/pages/compliance/index.vue")
  },
  {
    name: "compliance-ppm-logs",
    path: "ppm-logs",
    component: () => import("/var/www/admin-next/pages/compliance/ppm-logs.vue")
  },
  {
    name: "compliance-tasks",
    path: "tasks",
    component: () => import("/var/www/admin-next/pages/compliance/tasks.vue")
  }
]
  },
  {
    name: "deals-id",
    path: "/deals/:id()",
    component: () => import("/var/www/admin-next/pages/deals/[id]/index.vue")
  },
  {
    name: "deals-id-pro-rata-allocations",
    path: "/deals/:id()/pro-rata-allocations",
    component: () => import("/var/www/admin-next/pages/deals/[id]/pro-rata-allocations.vue")
  },
  {
    name: "deals",
    path: "/deals",
    component: () => import("/var/www/admin-next/pages/deals/index.vue")
  },
  {
    name: "documents-id",
    path: "/documents/:id()",
    component: () => import("/var/www/admin-next/pages/documents/[id].vue")
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/var/www/admin-next/pages/documents/index.vue")
  },
  {
    name: "events-id",
    path: "/events/:id()",
    component: () => import("/var/www/admin-next/pages/events/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/admin-next/pages/index.vue")
  },
  {
    name: "institutions-id",
    path: "/institutions/:id()",
    component: () => import("/var/www/admin-next/pages/institutions/[id].vue")
  },
  {
    name: "institutions",
    path: "/institutions",
    component: () => import("/var/www/admin-next/pages/institutions/index.vue")
  },
  {
    name: "investors-id",
    path: "/investors/:id()",
    component: () => import("/var/www/admin-next/pages/investors/[id].vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginFXjyPfbvrSMeta || {},
    component: () => import("/var/www/admin-next/pages/login.vue")
  },
  {
    name: mergev7gk2txNtZMeta?.name,
    path: "/merge",
    component: () => import("/var/www/admin-next/pages/merge.vue"),
    children: [
  {
    name: "merge-companies",
    path: "companies",
    component: () => import("/var/www/admin-next/pages/merge/companies.vue")
  },
  {
    name: "merge",
    path: "",
    component: () => import("/var/www/admin-next/pages/merge/index.vue")
  },
  {
    name: "merge-institutions",
    path: "institutions",
    component: () => import("/var/www/admin-next/pages/merge/institutions.vue")
  },
  {
    name: "merge-tags",
    path: "tags",
    component: () => import("/var/www/admin-next/pages/merge/tags.vue")
  },
  {
    name: "merge-users",
    path: "users",
    component: () => import("/var/www/admin-next/pages/merge/users.vue")
  }
]
  },
  {
    name: "outreach",
    path: "/outreach",
    component: () => import("/var/www/admin-next/pages/outreach.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/admin-next/pages/profile.vue")
  },
  {
    name: "projects-id",
    path: "/projects/:id()",
    component: () => import("/var/www/admin-next/pages/projects/[id].vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/var/www/admin-next/pages/projects/index.vue")
  },
  {
    name: prospectskFQp4wPKedMeta?.name,
    path: "/prospects",
    component: () => import("/var/www/admin-next/pages/prospects.vue"),
    children: [
  {
    name: "prospects-import",
    path: "import",
    component: () => import("/var/www/admin-next/pages/prospects/import.vue")
  },
  {
    name: "prospects",
    path: "",
    component: () => import("/var/www/admin-next/pages/prospects/index.vue")
  },
  {
    name: "prospects-lists-id",
    path: "lists/:id()",
    component: () => import("/var/www/admin-next/pages/prospects/lists/[id].vue")
  },
  {
    name: "prospects-outreach-analytics",
    path: "outreach-analytics",
    component: () => import("/var/www/admin-next/pages/prospects/outreach-analytics.vue")
  },
  {
    name: "prospects-referrals",
    path: "referrals",
    component: () => import("/var/www/admin-next/pages/prospects/referrals.vue")
  }
]
  },
  {
    name: "research-id",
    path: "/research/:id()",
    component: () => import("/var/www/admin-next/pages/research/[id].vue")
  },
  {
    name: "research",
    path: "/research",
    component: () => import("/var/www/admin-next/pages/research/index.vue")
  },
  {
    name: settingsliVKSfWa3BMeta?.name,
    path: "/settings",
    component: () => import("/var/www/admin-next/pages/settings.vue"),
    children: [
  {
    name: "settings-account-types",
    path: "account-types",
    component: () => import("/var/www/admin-next/pages/settings/account-types.vue")
  },
  {
    name: "settings-company-types",
    path: "company-types",
    component: () => import("/var/www/admin-next/pages/settings/company-types.vue")
  },
  {
    name: "settings-deal-stages",
    path: "deal-stages",
    component: () => import("/var/www/admin-next/pages/settings/deal-stages.vue")
  },
  {
    name: "settings-deal-types",
    path: "deal-types",
    component: () => import("/var/www/admin-next/pages/settings/deal-types.vue")
  },
  {
    name: "settings-document-categories",
    path: "document-categories",
    component: () => import("/var/www/admin-next/pages/settings/document-categories.vue")
  },
  {
    name: "settings-employment-roles",
    path: "employment-roles",
    component: () => import("/var/www/admin-next/pages/settings/employment-roles.vue")
  },
  {
    name: "settings-event-types",
    path: "event-types",
    component: () => import("/var/www/admin-next/pages/settings/event-types.vue")
  },
  {
    name: "settings-expense-types",
    path: "expense-types",
    component: () => import("/var/www/admin-next/pages/settings/expense-types.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/var/www/admin-next/pages/settings/index.vue")
  },
  {
    name: "settings-industries",
    path: "industries",
    component: () => import("/var/www/admin-next/pages/settings/industries.vue")
  },
  {
    name: "settings-labels",
    path: "labels",
    component: () => import("/var/www/admin-next/pages/settings/labels.vue")
  },
  {
    name: "settings-licenses",
    path: "licenses",
    component: () => import("/var/www/admin-next/pages/settings/licenses.vue")
  },
  {
    name: "settings-research-categories",
    path: "research-categories",
    component: () => import("/var/www/admin-next/pages/settings/research-categories.vue")
  },
  {
    name: "settings-task-priorities",
    path: "task-priorities",
    component: () => import("/var/www/admin-next/pages/settings/task-priorities.vue")
  },
  {
    name: "settings-task-types",
    path: "task-types",
    component: () => import("/var/www/admin-next/pages/settings/task-types.vue")
  },
  {
    name: "settings-user-types",
    path: "user-types",
    component: () => import("/var/www/admin-next/pages/settings/user-types.vue")
  }
]
  },
  {
    name: "tags-id",
    path: "/tags/:id()",
    component: () => import("/var/www/admin-next/pages/tags/[id].vue")
  },
  {
    name: "tags",
    path: "/tags",
    component: () => import("/var/www/admin-next/pages/tags/index.vue")
  },
  {
    name: "tasks-id",
    path: "/tasks/:id()",
    component: () => import("/var/www/admin-next/pages/tasks/[id].vue")
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/var/www/admin-next/pages/tasks/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/var/www/admin-next/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/var/www/admin-next/pages/users/index.vue")
  }
]